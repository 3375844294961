import React, { useState } from 'react';
import { DialogInput, DialogSelect, RequiredDialogInput, handleInputChange } from '../DialogInputs';
import CommonConfigDialog from './CommonConfigDialog';
import { useSaveAffectedArea, useDeleteAffectedArea } from '../../../../hooks/useAffectedAreas';
import { useGetBusinessUnits } from '../../../../hooks/useBusinessUnits';
import { useGetSites } from '../../../../hooks/useSites';
import { useTranslation } from 'react-i18next';

function AffectedAreaEditDialog({ dialogData, setDialogData, showDialog, closeDialog }) {

    const { t } = useTranslation();

    const [fieldErrors, setFieldErrors] = useState({});

    const { businessUnits } = useGetBusinessUnits();
    const { sites } = useGetSites();
    const { saveAffectedArea } = useSaveAffectedArea();
    const { deleteFunction } = useDeleteAffectedArea(dialogData);

    const onChange = (e) => {
        handleInputChange(e, dialogData, setDialogData, fieldErrors, setFieldErrors);
    }

    return (
        <CommonConfigDialog title={"Affected Area - " + dialogData.name} dialogData={dialogData} showDialog={showDialog} closeDialog={closeDialog} save={saveAffectedArea} canDelete deleteFunction={deleteFunction} fieldErrors={fieldErrors} setFieldErrors={setFieldErrors} >
            <RequiredDialogInput controlId="formAffectedAreaName" title="Name" placeholder="Name" name="name" value={dialogData.name} error={fieldErrors.name} onChange={onChange} />
            <DialogSelect controlId="formAffectedAreaBusinessUnit" title="Business Unit" name="businessUnitId" value={dialogData.businessUnitId} error={fieldErrors.businessUnitId} onChange={onChange}>
                <option value="" key={0} >{t('Application.Controls.DefaultSelect')}</option>
                {businessUnits.map((bu) => <option value={bu.id} key={bu.id}>{bu.name}</option>)}
            </DialogSelect>
            <DialogSelect controlId="formAffectedAreaSite" title="Site" name="siteId" value={dialogData.siteId} error={fieldErrors.siteId} onChange={onChange}>
                <option value="" key={0} >{t('Application.Controls.DefaultSelect')}</option>
                {sites.map((site) => <option value={site.id} key={site.id}>{site.name}</option>)}
            </DialogSelect>
            <DialogInput controlId="formAffectedAreaDisplay" title="Display" placeholder="Display" name="display" value={dialogData.display} error={fieldErrors.display} onChange={onChange} />
            <DialogInput controlId="formAffectedAreaTranslationKey" title="Translation Key" placeholder="Translation.Key" name="translationKey" value={dialogData.translationKey} error={fieldErrors.translationKey} onChange={onChange} />
        </CommonConfigDialog>
    );
}

export default AffectedAreaEditDialog;