import React from 'react';
import { Spinner } from 'react-bootstrap';
import DataTable from '../../DataTable/datatable.component';
import { Nav } from 'react-bootstrap';
import CommonConfigPage from './CommonConfigPage';
import { useGetCategories } from '../../../hooks/useCategories';
import CategoryEditDialog from './Dialogs/CategoryEditDialog';
import SelectFilter from '../../DataTable/datatable.selectfilter';

function CategoriesTable({ onRowClick, refresh }) {

    const { categories, loading } = useGetCategories(refresh);

    const tableColumns = [
        {
            Header: 'Name',
            accessor: 'name',
            filter: 'fuzzyText',
            Cell: (props) => {
                return <Nav><Nav.Link onClick={() => { onRowClick(props.row.original); }}>{props.value}</Nav.Link></Nav>
            }
        },
        {
            Header: 'Business Unit',
            accessor: 'businessUnitName',
            Filter: SelectFilter,
            filter: 'equals'
        },
        {
            Header: 'Site',
            accessor: 'siteName',
            Filter: SelectFilter,
            filter: 'equals'
        },
        {
            Header: 'Display',
            accessor: 'display',
            filter: 'fuzzyText'
        },
        {
            Header: 'Translation Key',
            accessor: 'translationKey',
            filter: 'fuzzyText'
        }
    ];

    if (loading) {
        return (
            <div className="d-flex justify-content-center">
                <Spinner animation="border" />
            </div>
        );
    }

    return <DataTable id="Categories" tableColumns={tableColumns} tableData={categories} />
}

function CategoriesConfiguration() {
    const getNewCategory = () => {
        return { id: null, name: "", businessUnitId: null }
    };

    return (
        <CommonConfigPage
            title="Categories"
            table={(showEditDialog, refreshList) => <CategoriesTable onRowClick={showEditDialog} refresh={refreshList} />}
            dialog={(dialogData, setDialogData, showDialog, closeDialog) => <CategoryEditDialog dialogData={dialogData} setDialogData={setDialogData} showDialog={showDialog} closeDialog={closeDialog} />}
            getNewConfigObject={getNewCategory}
        />
    );
}

export default CategoriesConfiguration;