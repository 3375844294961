import React from 'react';
import { t } from 'i18next';

function SelectFilter({ column: { filterValue, setFilter, preFilteredRows, id, options } }) {

    if (!options || options.length === 0) {

        // Calculate the options for filtering using the preFilteredRows
        options = new Set()
        preFilteredRows.forEach(row => {
            if (row.values[id] !== null && row.values[id] !== "")
                options.add(row.values[id])
        });
        options = [...options.values()].sort();
    }

    return (
        <select className="form-control form-control-sm"
            value={filterValue}
            onChange={e => { setFilter(e.target.value || undefined) }} >
            <option value="">{t("TableControls.Filter.All")}</option>
            {options.map((option, i) => (
                <option key={i} value={option}>
                    {option}
                </option>
            ))}
        </select>
    )
}

export default SelectFilter;