import { useState } from 'react';
import useAlert from '../components/Alerts/useAlert';
import { useHttpUtils } from '../utilities/useHttpUtils';

export const useDeleteBuilder = (url, successMessage, errorText) => {
    const { fetchDataAuthenticated } = useHttpUtils();
    const { setAlert } = useAlert();
    const [loading, setLoading] = useState(true);

    const deleteFunctionBuilder = (url, errorText) => async () =>{
        if (!url) {
            return;
        }
        let method = "DELETE";
        await fetchDataAuthenticated(url, method)
            .then(async response => {
                if (response.ok) {
                    return;
                }
                throw new Error(await response.text());
            })
            .then(() => {
                setAlert("success", "Successfully Deleted", successMessage)
            })
            .catch(error => {
                setAlert("danger", errorText, error.message);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const deleteFunction = deleteFunctionBuilder(url, errorText);

    return { deleteFunction, loading };
    }
