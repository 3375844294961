import { useState, useEffect } from 'react';
import useAlert from '../components/Alerts/useAlert';
import { useHttpUtils } from '../utilities/useHttpUtils';
import { useDeleteBuilder } from './hookBuilder';

export function useGetProductionLines(refresh) {

    const { fetchDataAuthenticated } = useHttpUtils();
    const { setAlert } = useAlert();

    const [loading, setLoading] = useState(true);
    const [productionLines, setProductionLines] = useState([]);

    useEffect(() => {
        getProductionLines();
    }, [refresh]);

    async function getProductionLines() {

        await fetchDataAuthenticated("api/productionLines")
            .then(async response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(await response.text());
            })
            .then(data => {
                setProductionLines(data);
            })
            .catch(error => {
                console.error(error.message);
                setAlert("danger", "Failed to get production lines", error.message);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    return { productionLines, loading };
}

export function useGetProductionLinesByBusinessUnit(businessUnitId) {

    const { fetchDataAuthenticated } = useHttpUtils();
    const { setAlert } = useAlert();

    const [productionLines, setProductionLines] = useState([]);

    useEffect(() => {
        if (businessUnitId) {
            getProductionLines(businessUnitId);
        }
    }, [businessUnitId]);

    async function getProductionLines(businessUnitId) {

        await fetchDataAuthenticated(`api/businessUnit/${businessUnitId}/productionLines`)
            .then(async response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(await response.text());
            })
            .then(data => {
                setProductionLines(data);
            })
            .catch(error => {
                console.error(error.message);
                setAlert("danger", `Failed to get production lines for business unit ${businessUnitId}`, error.message);
            })
    }

    return productionLines;
}

export function useSaveProductionLine() {
    const { fetchDataAuthenticated } = useHttpUtils();
    const { setAlert } = useAlert();
    const [loading, setLoading] = useState(true);

    async function saveProductionLine(productionLine) {

        let url = "api/productionLines";
        let method = "POST";
        if (productionLine.id != null) {
            url += `/${productionLine.id}`;
            method = "PUT";
        }

        await fetchDataAuthenticated(url, method, JSON.stringify(productionLine))
            .then(async response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(await response.text());
            })
            .then(() => {
                console.log(`Saved production line ${productionLine.id}`, productionLine)
            })
            .catch(error => {
                console.error(error.message);
                setAlert("danger", "Failed to save production line", error.message);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    return { saveProductionLine, loading }
}

export function useDeleteProductionLine(productionLine) {
    let url = `api/productionLines/${productionLine.id}`;
    let successMessage = `Successfully deleted production line ${productionLine.name}`;
    let errorMessage = "Failed to delete production line";
    const { deleteFunction, loading } = useDeleteBuilder(url, successMessage, errorMessage);

    return { deleteFunction, loading };
}