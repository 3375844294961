import { useState, useEffect } from 'react';
import useAlert from '../components/Alerts/useAlert';
import { useHttpUtils } from '../utilities/useHttpUtils';

export function useGetSites(refresh) {

    const { fetchDataAuthenticated } = useHttpUtils();
    const { setAlert } = useAlert();

    const [loading, setLoading] = useState(true);
    const [sites, setSites] = useState([]);

    useEffect(() => {
        getSites();
    }, [refresh]);

    async function getSites() {

        await fetchDataAuthenticated("api/site")
            .then(async response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(await response.text());
            })
            .then(data => {
                setSites(data);
            })
            .catch(error => {
                console.error(error.message);
                setAlert("danger", "Failed to get sites", error.message);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    return { sites, loading }
}


export function useSaveSite() {

    const { fetchDataAuthenticated } = useHttpUtils();
    const { setAlert } = useAlert();

    const [loading, setLoading] = useState(true);

    async function saveSite(site) {

        let url = "api/site";
        let method = "POST";
        if (site.id != null) {
            url += `/${site.id}`;
            method = "PUT";
        }

        await fetchDataAuthenticated(url, method, JSON.stringify(site))
            .then(async response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(await response.text());
            })
            .then(() => {
                console.log(`Saved site ${site.id}`, site)
            })
            .catch(error => {
                console.error(error.message);
                setAlert("danger", "Failed to save site", error.message);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    return { saveSite, loading }
}