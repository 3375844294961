import { useState, useEffect } from 'react';
import useAlert from '../components/Alerts/useAlert';
import { useHttpUtils } from '../utilities/useHttpUtils';

export function useGetConfigurationDataProperties(refresh) {

    const { fetchDataAuthenticated } = useHttpUtils();
    const { setAlert } = useAlert();

    const [loading, setLoading] = useState(true);
    const [configurationDataProperties, setConfigurationDataProperties] = useState([]);

    useEffect(() => {
        getConfigurationDataProperties();
    }, [refresh]);

    async function getConfigurationDataProperties() {

        await fetchDataAuthenticated("api/configurationDataProperty")
            .then(async response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(await response.text());
            })
            .then(data => {
                setConfigurationDataProperties(data);
            })
            .catch(error => {
                console.error(error.message);
                setAlert("danger", "Failed to get configuration data properties", error.message);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    return { configurationDataProperties, loading }
}


export function useSaveConfigurationDataProperty() {

    const { fetchDataAuthenticated } = useHttpUtils();
    const { setAlert } = useAlert();
    const [loading, setLoading] = useState(true);

    async function saveConfigurationDataProperty(configurationDataProperty) {

        let url = "api/configurationDataProperty";
        let method = "POST";
        if (configurationDataProperty.id != null) {
            url += `/${configurationDataProperty.id}`;
            method = "PUT";
        }

        await fetchDataAuthenticated(url, method, JSON.stringify(configurationDataProperty))
            .then(async response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(await response.text());
            })
            .then(() => {
                console.log(`Saved configuration data property ${configurationDataProperty.id}`, configurationDataProperty)
            })
            .catch(error => {
                console.error(error.message);
                setAlert("danger", "Failed to save configuration data propert", error.message);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    return { saveConfigurationDataProperty, loading }
}