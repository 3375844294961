import React, { useState, useEffect, useContext } from 'react';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

import { client as publicClientApplication } from '../../utilities/AuthUtils';
import PrivilegesContext from '../../contexts/PrivilegesContext';
import { useCreateQIR, useGetQIR, useUpdateQIR } from '../../hooks/useQIRs';
import DeleteQIRButton from './DeleteQIRButton';
import QIRComponent from './QIRComponent';
import AddCommentDialog from '../Dialogs/AddCommentDialog';

function UpsertQIR({action}) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { qirId } = useParams();
    const { admin } = useContext(PrivilegesContext);
    const { createQIR } = useCreateQIR();
    const { qir: qirDetail, getQIR, loading: loadingGetQIR } = useGetQIR(qirId);
    const { updateQIR, loading: loadingUpdateQIR } = useUpdateQIR();
    const [showCommentDialog, setShowCommentDialog] = useState(false);
    const [qir, setQir] = useState({});

    useEffect(() => {
        switch (action) {
            case "create":
                const account = publicClientApplication.getAllAccounts()[0];
                setQir({
                    ...qir,
                    originator: account.name
                });
                break;
            case "update":
                setQir(qirDetail);
                break;
            default:
                throw new Error(`Invalid action for UpsertQIR componet, expected create or update but got ${action}`);
        }
    }, [qirDetail]);

    const saveChanges = async (comment, commentReasonCodeId) => {
        qir.comment = comment;
        qir.commentReasonCodeId = commentReasonCodeId;
        switch (action) {
            case "create":
                await createQIRAndNavigateBackToList(qir);
                break;
            case "update":
                await updateQIRAndRefresh(qir);
                break;
            default:
                throw new Error(`Invalid action for UpsertQIR componet, expected create or update but got ${action}`);
        }
    }

    const createQIRAndNavigateBackToList = async () => {
        let success = await createQIR(qir);
        if (success) {
            navigate("/qirs");
        }
    }

    const updateQIRAndRefresh = async (qir) => {
        let success = await updateQIR(qir)
        if (success) {
            getQIR();
        }
    }

    if (action==="update" && (loadingGetQIR || loadingUpdateQIR)) {
        return (
            <div className="d-flex justify-content-center">
                <Spinner animation="border" />
            </div>
        );
    }

    return (
        <>
            {action === "create" &&
                <Helmet>
                    <title>{t("CreateQIR.TabTitle")} </title>
                </Helmet>
            }
            {action === "update" && admin &&
                <div className="d-flex justify-content-end me-3 mb-2">
                    <DeleteQIRButton qirId={qirId} />
                </div>
            }
            <QIRComponent
                qir={qir}
                setQir={setQir}
                action={action}
                submitButtonText={action === "create" ? t("CreateQIR.CreateButton") : t("UpdateQIR.UpdateButton")}
                onSubmit={() => setShowCommentDialog(true)}
                refresh={action === "create" ? null : getQIR}
            />
            <AddCommentDialog showDialog={showCommentDialog} closeDialog={() => setShowCommentDialog(false)} businessUnitId={qir?.businessUnitId} onSave={saveChanges} />
        </>
    );
}

export default UpsertQIR;