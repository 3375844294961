import { useState, useEffect } from 'react';
import useAlert from '../components/Alerts/useAlert';
import { useHttpUtils, correlationId } from '../utilities/useHttpUtils';

export function useGetQIRComments(qirId) {

    const { fetchDataAuthenticated } = useHttpUtils();
    const { setAlert } = useAlert();

    const [loading, setLoading] = useState();
    const [qirComments, setQirComments] = useState([]);

    useEffect(() => {
        if (qirId) {
            setLoading(true);
            getQirComments(qirId);
        }
    }, [qirId]);

    async function getQirComments(qirId) {
        setLoading(true);

        await fetchDataAuthenticated(`api/qir/${qirId}/comments`)
            .then(async response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(await response.text());
            })
            .then(data => {
                setQirComments(data);
            })
            .catch(error => {
                console.error(error.message);
                setAlert("danger", "Failed to get QIR comments", error.message);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return { getQirComments, qirComments, loading }
}

export function useAddQIRComment() {
    const { fetchDataAuthenticated } = useHttpUtils();
    const { setAlert } = useAlert();

    let qirCommentAdded;
    const [loading, setLoading] = useState(true);

    async function addQIRComment(qirId, comment, commentReasonCodeId) {

        setLoading(true);
        const qirComment = {
            comment: comment,
            commentReasonCodeId: commentReasonCodeId
        };

        await fetchDataAuthenticated(`api/qir/${qirId}/comments`, "PUT", JSON.stringify(qirComment) )
            .then(async response => {
                if (response.ok) {
                    return;
                }
                throw new Error(await response.text());
            })
            .then(() => {
                setAlert("success", "Success", `Added QIR comment to ${qirId}`);
                qirCommentAdded = true;
            })
            .catch(error => {
                qirCommentAdded = false;
                console.error(error.message);
                setAlert("danger", `Failed to add QIR comment to ${qirId} [${correlationId}]`, error.message);
            })
            .finally(() => {
                setLoading(false);
            });

        return qirCommentAdded;
    }

    return { addQIRComment, loading }
}