import React, { useContext } from 'react';
import { useInterval } from 'usehooks-ts';
import { OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import DataTable from '../DataTable/datatable.component';
import { Link } from 'react-router-dom';
import { getTableFilters, getSortOrder } from '../../utilities/SessionStorage';
import SelectFilter from '../DataTable/datatable.selectfilter';
import NoFilter from '../DataTable/datatable.nofilter';
import { useGetQIRs } from '../../hooks/useQIRs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import DateColumn from '../DataTable/datatable.datecolumn';
import BusinessUnitAccessContext from '../../contexts/BusinessUnitAccessContext';
import PrivilegesContext from '../../contexts/PrivilegesContext';

function QIRsTable({ tableName, businessUnits, dateFrom, dateTo, user, closed, setCSVData }) {

    const { t } = useTranslation();
    const { qirs, getQIRs, loading } = useGetQIRs(businessUnits, dateFrom, dateTo, user, closed);
    const { contributor } = useContext(PrivilegesContext);
    const { assignedBusinessUnits } = useContext(BusinessUnitAccessContext);
    
    // update table at interval
    useInterval(async () => {
        getQIRs(businessUnits, dateFrom, dateTo, user, closed);
    }, 30000);

    const truncateStatement = (statement) => {
        if (statement.length > 100) {
            statement = statement.slice(0, 100);
            statement += "...";
        }
        return statement;
    }

    const setCSVDataEscapeQuotesInStatement = (csvData) => {
        setCSVData(csvData.map(item => ({ ...item, statement: item.statement.replace(/"/g, '""') })));
    }

    const tableColumns = [
        {
            Header: t('QIRTable.ColHead.Id'),
            accessor: 'id',
            filter: 'includesString',
            Cell: (props) => {
                const url = contributor ? `/qir/${props.value}` : `/qir/view/${props.value}`
                return (
                    <OverlayTrigger overlay={<Tooltip>{truncateStatement(props.row.original.statement)}</Tooltip>}>
                        <span className="d-flex"><Link to={{ pathname: url }}>{props.value}</Link></span>
                    </OverlayTrigger>
                );
            }
        },
        {
            Header: t('QIRTable.ColHead.Status'),
            accessor: 'statusDisplay',
            Filter: SelectFilter,
            filter: 'equals',
            Cell: (props) => props.row.original.statusDisplay ?? props.row.original.status
        },
        {
            Header: t('QIRTable.ColHead.BusinessUnit'),
            accessor: 'businessUnitDisplay',
            Filter: SelectFilter,
            filter: 'equals'
        },
        {
            Header: t('QIRTable.ColHead.ProductionLine'),
            accessor: 'productionLineDisplay',
            Filter: SelectFilter,
            filter: 'equals'
        },
        {
            Header: t('QIRTable.ColHead.LotNumber'),
            accessor: 'lotNumber',
            filter: 'includesString'
        },
        {
            Header: t('QIRTable.ColHead.Grade'),
            accessor: 'grade',
            Filter: SelectFilter,
            filter: 'equals'
        },
        {
            Header: t('QIRTable.ColHead.AffectedArea'),
            accessor: 'affectedAreaDisplay',
            Filter: SelectFilter,
            filter: 'equals'
        },
        {
            Header: t('QIRTable.ColHead.Category'),
            accessor: 'categoryDisplay',
            Filter: SelectFilter,
            filter: 'equals'
        },
        {
            Header: t('QIRTable.ColHead.ReportedBy'),
            accessor: 'createdBy',
            filter: 'includesString'
        },
        DateColumn({ header: t('QIRTable.ColHead.IncidentDate'), accessor: 'incidentDate' }),
        DateColumn({ header: t('QIRTable.ColHead.ReportedDate'), accessor: 'createdDate' }),
        {
            accessor: 'update',
            Filter: NoFilter,
            Cell: (props) => {
                return (
                    <Link to={{ pathname: `/qir/${props.row.values.id}` }}>
                        <FontAwesomeIcon className="link" icon={faPenToSquare} />
                    </Link>
                )
            }
        }
    ];

    if (loading) {
        return (
            <div className="d-flex justify-content-center">
                <Spinner animation="border" />
            </div>
        );
    }

    let initialState = {
        pageSize: 20,
        hiddenColumns: ['qirId'],
        sortBy: [
            {
                id: 'commentDate',
                desc: true
            }
        ]
    }

    if (assignedBusinessUnits && assignedBusinessUnits.length === 1) {
        initialState.hiddenColumns.push("businessUnit")
    }

    if (!contributor) {
        initialState.hiddenColumns.push("update");
    }

    const tableId = tableName ?? "qirs-table";
    const sortBy = getSortOrder(tableId);
    const filters = getTableFilters(tableId);

    if (sortBy) {
        initialState.sortBy = sortBy;
    }

    if (filters) {
        initialState.filters = filters;
    }

    return qirs && qirs.length > 0
        ? <DataTable id={tableId} tableColumns={tableColumns} tableData={qirs} initialState={initialState} onDataFiltered={setCSVDataEscapeQuotesInStatement} />
        :
        <div className="d-flex flex-column mt-5">
            <h4 className="text-center">{t('QIRList.NoRecords')}</h4>
        </div>
}

export default QIRsTable;